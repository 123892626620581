window.globalLocale={
      locale: 'en',
      en: {
        register: {
          title: 'NEW ACCOUNT REGISTRATION',
          subtitle_q: 'Select Language*',
          have_acc_yes: 'YES',
          have_acc_no: 'NO',
          have_acc_label: 'Have an Existing Account?',
          yes_description: 'If you are an existing customer, adding a new account is fast and hassle free! Just click on "New Account" on the Dashboard of the Client Area. You can also add a New Account and manage your account settings in My Profile. Contact us for more information.',
          login_button: 'Login',
          acc_info: 'Account Information',
          acc_info_rq: 'Required',
          acc_type_selection: 'Account Type',
          acc_curr_selection: 'Account Currency',
          acc_type_bussines: 'Business',
          acc_type_personal: 'Personal',
          agree_terms: 'Do you agree to our:',
          terms: 'Terms & Conditions',
          privacy: 'Privacy Policy',
          submit_reg: 'Submit Registration',
          retry_reg: 'Retry Registration',
          accept_button: 'Accept',
          cancel_button: 'Cancel',
          must_complete_terms: 'You must complete to the bottom of the Terms in order to accept.',
          document_upload_error: 'Document upload error',
          document_upload_error_text_1: 'There was an issue with your registration documents. Please select a new file to upload and click below to retry your registration.',
          document_upload_error_text_2: 'Please contact support if you continue to experience issues.',
          business_acc_info_header: 'Business Information',
          buss_legal_name: 'Business Legal Name',
          buss_dba_name: 'Business DBA Name',
          buss_inc_date: 'Business Incorporation Date',
          buss_tax_name: 'Business Tax ID',
          country_formation: 'Country of Formation',
          prohibited_c: 'Prohibited Countries',
          state_formation: 'State/Province of Formation',
          business_types: 'Business Type',
          bus_type_sole: 'Sole Proprietor',
          bus_type_incorporation: 'Incorporation',
          bus_type_smllc: 'Single Member LLC',
          bus_type_llc: 'LLC',
          bus_type_gen_par: 'General Partnership',
          bus_type_corp: 'Corporation',
          bus_type_ptc: 'Publicly Traded Corporation',
          bus_type_association: 'Association',
          bus_type_n_profit: 'Non Profit',
          bus_type_gov_org: 'Government Organization',
          bus_type_rev_trust: 'Revocable Trust',
          bus_type_irr_trust: 'Irrevocable Trust',
          bus_type_estate: 'Estate',
          business_address: 'Business Address',
          business_address_2: 'Business Address 2',
          business_city: 'Business City',
          business_state: 'Business State/Province',
          business_zip: 'Business ZIP / Postal Code',
          business_phone: 'Business Phone',
          business_email: 'Business Email',
          business_website: 'Business Website',
          account_contacts: 'Contacts Information',
          add_ID: 'Add Another ID',
          remove_ID: 'Remove ID',
          add_another_contact: 'Add Another Contact',
          remove_contact: 'Remove Contact',
          contact_type: 'Contact Type',
          ben_owner: 'Beneficial Owner',
          auth_person: 'Authorized person',
          contact_gender: 'Sex',
          gender_m: 'Male',
          gender_f: 'Female',
          contact_birth: 'Date of Birth',
          contact_FN: 'First Name',
          contact_LN: 'Last Name',
          contact_country: 'Country',
          contact_city: 'City',
          contact_address: 'Address',
          contact_address_2: 'Address 2',
          contact_state: 'State/Province',
          contact_zip: 'ZIP / Postal Code',
          contact_phone: 'Phone Number',
          contact_mobile: 'Mobile Number',
          contact_email: 'Email Address',
          contact_ssn: 'SSN/Fiscal Number',
          whats_this: 'What\'s this?',
          gov_issued_id_type: 'Government Issued ID Type',
          gov_issued_id_num: 'Government Issued ID Number',
          gov_doc_passport: 'Passport',
          gov_doc_drivers_license: 'Drivers License',
          ownership_percent: 'Ownership Percent',
          modal_ssn: 'SSN/FISCAL NUMBER',
          modal_ssn_line1: 'US Citizens: Enter your social security number',
          modal_ssn_line2: 'For Citizens outside of the US: Enter your government tax identification number',
          year_format: 'YR',
          business_desc_questions: 'Business Description Questions',
          business_purpose: 'Purpose of Account',
          business_purpose_select_1: 'Custody of Funds',
          business_purpose_select_2: 'Custody of Digital Assets',
          business_purpose_select_3: 'Custody of Funds & Digital Assets',
          business_associations_other_acc: 'Association with Other Accounts',
          business_associations_other_acc_select_1: 'None',
          business_associations_other_acc_select_2: 'I also have a Personal Account',
          business_associations_other_acc_select_3: 'I also have a Business Account',
          business_source_assets: 'Source of Assets and Income',
          business_source_assets_personal: 'Personal',
          business_source_assets_business: 'Business',
          business_intended_use_of_account: 'Intended Use of Account',
          business_intended_use_of_account_select_1: 'Custody of Funds for Personal Use',
          business_intended_use_of_account_select_2: 'Custody of Digital Assets for Personal Use',
          business_intended_use_of_account_select_3: 'Custody of Funds & Digital Assets for Personal Use',
          business_intended_use_of_account_select_4: 'Custody of Funds for Business Use',
          business_intended_use_of_account_select_5: 'Custody of Digital Assets for Business Use',
          business_intended_use_of_account_select_6: 'Custody of Funds & Digital Assets for Business Use',
          business_anticipated_types_of_assets: 'Anticipated Types of Assets',
          business_anticipated_types_of_assets_select_1: 'Cash',
          business_anticipated_types_of_assets_select_2: 'Digital Assets',
          business_anticipated_types_of_assets_select_3: 'Cash & Digital Assets',
          business_anticipated_monthly_cash_volume: 'Anticipated Monthly Cash Volume',
          business_anticipated_trading_patterns: 'Anticipated Trading Patterns',
          business_anticipated_trading_patterns_select_1: 'None',
          business_anticipated_trading_patterns_select_2: 'Regular',
          business_anticipated_trading_patterns_select_3: 'Rare',
          business_anticipated_monthly_transactions_incoming: 'Anticipated Monthly Transactions Incoming',
          business_anticipated_monthly_transactions_outgoing: 'Anticipated Monthly Transactions Outgoing',
          business_industry: 'Business Industry',
          administrative_services: 'Administrative Services',
          advertising: 'Advertising',
          animal_farming_production: 'Animal Farming Production',
          art_photography: 'Art Photography',
          auto_dealers: 'Auto Dealers',
          automotive: 'Automotive',
          bank: 'Bank',
          beauty_or_barber_shops: 'Beauty Or Barber Shops',
          biotechnology: 'Biotechnology',
          building_materials_hardware: 'Building Materials Hardware',
          car_wash: 'Car Wash',
          clothing_apparel: 'Clothing Apparel',
          college_university_schools: 'College University Schools',
          computer_service_repair: 'Computer Service Repair',
          construction: 'Construction',
          consumer_goods: 'Consumer Goods',
          crop_farming: 'Crop Farming',
          data_analytics: 'Data Analytics',
          design: 'Design',
          ecommerce: 'Ecommerce',
          electronics: 'Electronics',
          employment_services: 'Employment Services',
          energy: 'Energy',
          engineering: 'Engineering',
          events: 'Events',
          financial_investments: 'Financial Investments',
          financial_services: 'Financial Services',
          financial_technology: 'Financial Technology',
          fishing_hunting: 'Fishing/Hunting',
          fitness_sports_centers: 'Fitness Sports Centers',
          food: 'Food',
          forestry: 'Forestry',
          freelance_professional: 'Freelance Professional',
          funds_trusts_other: 'Funds Trusts Other',
          gaming: 'Gaming',
          gasoline_service_station: 'Gasoline Service Station',
          government_agency: 'Government Agency',
          hardware: 'Hardware',
          health_services: 'Health Services',
          home_furnishing: 'Home Furnishing',
          hospitals: 'Hospitals',
          hotel_motel: 'Hotel/Motel',
          industrial_machinery: 'Industrial Machinery',
          information_technology: 'Information Technology',
          insurance: 'Insurance',
          landscape_services: 'Landscape Services',
          legal_services: 'Legal Services',
          lifestyle: 'Lifestyle',
          massage_tanning_services: 'Massage Tanning Services',
          mobile: 'Mobile',
          money_transfer_remittance: 'Money Transfer Remittance',
          museums: 'Museums',
          music: 'Music',
          natural_resources: 'Natural Resources',
          non_government: 'Non Government',
          online_retailer: 'Online Retailer',
          other_accommodation: 'Other Accommodation',
          other_arts_entertainment: 'Other Arts Entertainment',
          other_education_services: 'Other Education Services',
          other_farming_hunting: 'Other Farming/Hunting',
          other_food_services: 'Other Food Services',
          other_health_fitness: 'Other Health Fitness',
          other_manufacturing: 'Other Manufacturing',
          other_professional_services: 'Other Professional Services',
          other_trade_contractor: 'Other Trade Contractor',
          other_transport_services: 'Other Transport Services',
          other_travel_services: 'Other Travel Services',
          parking_garages: 'Parking Garages',
          payments: 'Payments',
          plumbing_hvac: 'Plumbing HVAC',
          private_investment: 'Private Investment',
          publishing: 'Publishing',
          real_estate: 'Real Estate',
          religious_organization: 'Religious Organization',
          restaurants_with_cash: 'Restaurants With Cash',
          restaurants: 'Restaurants',
          retail_jeweler: 'Retail Jeweler',
          retail_with_cash: 'Retail With Cash',
          retail: 'Retail',
          sales_marketing: 'Sales Marketing',
          security: 'Security',
          software_publisher: 'Software Publisher',
          sports_teams_clubs: 'Sports Teams Clubs',
          sports: 'Sports',
          sustainability: 'Sustainability',
          taxi: 'Taxi',
          telecommunication: 'Telecommunication',
          tour_operator: 'Tour Operator',
          transportation: 'Transportation',
          travel_agency: 'Travel Agency',
          trucking_shipping: 'Trucking/Shipping',
          unions: 'Unions',
          used_clothes_dealer: 'Used Clothes Dealer',
          video: 'Video',
          warehouse_distribution: 'Warehouse Distribution',
          wellness_services: 'Wellness Services',
          wholesale_jeweler: 'Wholesale Jeweler',
          wholesale: 'Wholesale',
          business_desc: 'Business Description',
          business_doc_submission: 'Document Submission',
          business_nature: 'Nature of Business of the Company',
          business_inc_doc: 'Business Incorporation Documents (10MB max)',
          business_address_confirmation: 'Business Address Confirmation (10MB max)',
          contact_increment_ID: 'Contact',
          contact_increment_ID_text: 'Government Issued ID Type',
          contact_increment_address_text: 'Address Confirmation',
          bus_inc_doc_header: 'Business Incorporation Documents',
          bus_inc_doc_text: 'Please submit your complete business incorporation documents which can include the company charter, articles of incorporation, bylaws, operating agreement, ownership share registry, etc.',
          bus_address_conf_header: 'Business Address Confirmation',
          bus_address_conf_text: 'Please provide proof of address (mail item) dated within the last 90 days (e.g., Monthly Bill Statement, Bank/Credit Card Account Statement, Rental Lease Agreement, Vehicle Registration, Copy of Real Estate Property Title or Property Tax Bill).',
          bus_gov_issues_ID_popup_header: 'Government Issued ID',
          bus_gov_issues_ID_popup_line1: 'Please follow these instructions when submitting your government issued ID.  Submitted ID documents that do not meet the below criteria may cause approval delays.',
          bus_gov_issues_ID_popup_line2: 'Scan of the passport should be of the full photograph page and showing the full opposite page as well.  Both pages must show their complete margin.  Scan must be in color and have the least amount of reflections and glare as possible.',
          bus_gov_issues_ID_popup_line3: 'Drivers License:  Scan of the drivers license should be in full and showing all margins.  Scan must be in color and have the least amount of reflections and glare as possible.',
          choose_file: 'Choose File',
          personal_acc_heading: 'Contact Information',
          personal_address_conf_header: 'Address Information',
          personal_doc_submission: 'Document Submission',
          unable_1: 'We are unable to complete your request at this time. Please contact support at',
          unable_2: 'or by phone at',
          unable_3: 'to complete your request',
          unable_4: 'Thank you',
          final_acc_activation: 'Final Account Activation will only occur once all documents have been received.',
          required1: 'You need to fill all required fields. We marked them with red color so that you know what fields you have missed.',
          required2: 'Ownership Percentage of all Beneficial Owners needs to be a total of 100%',
          required3: 'At least one Beneficial Owner must be selected',
          form_error_required: 'This field is required.',
          form_error_email: 'This should be a valid email.',
          form_error_sameAs: 'Passwords must match.',
          form_error_integer: 'The value must be integer.',
          form_error_numeric: 'This field must be numeric.',
          form_error_decimal: 'This field must be a number.',
          form_error_between: 'The value is out of range.',
          form_error_alpha: 'This field must be letters only.',
          form_error_alphaNum: 'This field does not allow special characters.',
          form_error_alphaNumNoSpace: 'This field must be alphanumeric.',
          form_error_alphaOnly: 'This field must be letters only.',
          form_error_alphaVersionNoSpace: 'This field does not allow special characters.',
          form_error_addressValidation: 'This field does not allow special characters.',
          form_error_dashNumbers: 'This field does not allow special characters.',
          form_error_ipAddress: 'The value must be a valid IPv4 address.',
          form_error_url: 'The value must be a valid URL.',
          form_error_minLength: 'More characters required.',
          form_error_maxLength: 'Maximum character limit exceeded.',
          form_error_minValue: 'The value can not be less than zero.',
          form_error_limit: 'Amount Entered Exceeds Limit',
          form_error_routingNumber: 'Routing number can only start with 0, 1, 2 or 3',
          form_error_monbiAccountNumber: 'This is not a valid Account number. Please check information and try again.',
          form_error_swift: 'Enter a valid SWIFT code.',
          form_error_minLimit: 'The Amount must be greater than the Fee.',
          form_error_webSiteUrl: 'This field must be url format.',
        },
        months: {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
          8: 'August',
          9: 'September',
          10: 'October',
          11: 'November',
          12: 'December',
        },
      },
      es: {
        register: {
          title: 'REGISTRO DE NUEVA CUENTA',
          subtitle_q: 'Escoja el idioma*',
          have_acc_yes: 'SÍ',
          have_acc_label: '¿Tiene una cuenta existente?',
          yes_description: 'Si ya sos un cliente, ¡agregar una nueva cuenta es rápido y sin complicaciones! Simplemente hacé clic en "Nueva Cuenta" en el Dashboard del Área de Cliente. También podes agregar una nueva cuenta y administrar la configuración de tu cuenta en Mi perfil. Contactanos para más información.',
          login_button: 'Iniciar sesión',
          acc_info: 'Información de la cuenta',
          acc_info_rq: 'Requerido',
          acc_type_selection: 'Tipo de Cuenta',
          acc_curr_selection: 'Moneda',
          acc_type_bussines: 'Empresa',
          acc_type_personal: 'Personal',
          agree_terms: 'Aceptar:',
          terms: 'Términos y condiciones',
          privacy: 'Política de privacidad',
          submit_reg: 'Enviar registro',
          retry_reg: 'Reintentar registro',
          accept_button: 'Aceptar',
          cancel_button: 'Cancelar',
          must_complete_terms: 'Debe completar hasta el final de los Términos para aceptar.',
          document_upload_error: 'Error al subir documento',
          document_upload_error_text_1: 'Hubo un problema con sus documentos de registro. Seleccione un nuevo archivo para cargar y haga clic a continuación para volver a intentar su registro.',
          document_upload_error_text_2: 'Póngase en contacto con el soporte si continúa experimentando problemas.',
          business_acc_info_header: 'Información de Empresa',
          buss_legal_name: 'Nombre legal de la empresa',
          buss_dba_name: 'Nombre comercial de la empresa',
          buss_inc_date: 'Fecha de incorporación',
          buss_tax_name: 'Identificación fiscal de la empresa',
          country_formation: 'País de formación',
          prohibited_c: 'Países prohibidos',
          state_formation: 'Estado/Provincia de formación',
          business_types: 'Tipo de negocio',
          bus_type_sole: 'Propietario único',
          bus_type_smllc: 'Compañía de responsabilidad limitada de miembro único',
          bus_type_llc: 'Compañía de responsabilidad limitada',
          bus_type_gen_par: 'Sociedad General',
          bus_type_corp: 'Corporación',
          bus_type_ptc: 'Corporación que cotiza en bolsa',
          bus_type_association: 'Asociación',
          bus_type_n_profit: 'Sin fines de lucro',
          bus_type_gov_org: 'Organización gubernamental',
          bus_type_rev_trust: 'Fideicomiso revocable',
          bus_type_irr_trust: 'Fideicomiso irrevocable',
          bus_type_estate: 'Bienes',
          business_address: 'Dirección de la empresa',
          business_address_2: 'Dirección de la empresa 2',
          business_city: 'Ciudad de la Empresa',
          business_state: 'Estado/Provincia de la Empresa',
          business_zip: 'Código postal de la empresa',
          business_phone: 'Teléfono de la empresa',
          business_email: 'Email de la empresa',
          business_website: 'Sitio web de la empresa',
          account_contacts: 'Información de Contactos',
          add_ID: 'Agregar otra identificación',
          remove_ID: 'Quitar identificación',
          add_another_contact: 'Agregar otro contacto',
          remove_contact: 'Quitar Contacto',
          contact_type: 'Tipo de Contacto',
          ben_owner: 'Propietario efectivo',
          auth_person: 'Persona autorizada',
          contact_gender: 'Sexo',
          gender_m: 'Masculino',
          gender_f: 'Femenino',
          contact_birth: 'Fecha de Nacimiento',
          contact_FN: 'Nombre',
          contact_LN: 'Apellido',
          contact_country: 'País',
          contact_city: 'Ciudad',
          contact_address: 'Dirección',
          contact_address_2: 'Dirección 2',
          contact_state: 'Estado/Provincia',
          contact_zip: 'Código Postal',
          contact_phone: 'Número de teléfono',
          contact_mobile: 'Número de teléfono celular',
          contact_email: 'Correo electrónico',
          contact_ssn: 'SSN/Número de identificación fiscal',
          whats_this: '¿Qué es esto?',
          gov_issued_id_type: 'Tipo de identificación emitida por el gobierno',
          gov_issued_id_num: 'Número de identificación emitido por el gobierno',
          gov_doc_passport: 'Pasaporte',
          gov_doc_drivers_license: 'DNI/Licencia de conducir',
          ownership_percent: 'Porcentaje de propiedad',
          modal_ssn: 'SSN/Número de identificación fiscal',
          modal_ssn_line1: 'Ciudadanos estadounidenses: ingresar número de seguro social',
          modal_ssn_line2: 'Para ciudadanos fuera de los EE. UU.: Ingresar número de identificación fiscal del gobierno',
          year_format: 'AA',
          business_desc_questions: 'Cuenta de Empresa: Preguntas Sobre la Empresa',
          business_purpose: 'Propósito de la cuenta',
          business_purpose_select_1: 'Custodia de Fondos',
          business_purpose_select_2: 'Custodia de Activos Digitales',
          business_purpose_select_3: 'Custodia de Fondos y Activos Digitales',
          business_associations_other_acc: 'Asociación con otras cuentas',
          business_associations_other_acc_select_1: 'Ninguna',
          business_associations_other_acc_select_2: 'También tengo una cuenta personal',
          business_associations_other_acc_select_3: 'También tengo una cuenta de empresa',
          business_source_assets: 'Fuente de Activos e Ingresos',
          business_source_assets_personal: 'Personal',
          business_source_assets_business: 'Empresa',
          business_intended_use_of_account: 'Uso previsto de la cuenta',
          business_intended_use_of_account_select_1: 'Custodia de Fondos para Uso Personal',
          business_intended_use_of_account_select_2: 'Custodia de Activos Digitales para Uso Personal',
          business_intended_use_of_account_select_3: 'Custodia de Fondos y Activos Digitales para Uso Personal',
          business_intended_use_of_account_select_4: 'Custodia de Fondos para Uso Comercial',
          business_intended_use_of_account_select_5: 'Custodia de Activos Digitales para Uso Comercial',
          business_intended_use_of_account_select_6: 'Custodia de Fondos y Activos Digitales para Uso Comercial',
          business_anticipated_types_of_assets: 'Tipos de Activos Anticipados',
          business_anticipated_types_of_assets_select_1: 'Fondos',
          business_anticipated_types_of_assets_select_2: 'Activos digitales',
          business_anticipated_types_of_assets_select_3: 'Fondos y activos digitales',
          business_anticipated_monthly_cash_volume: 'Volumen de efectivo anticipado por mes',
          business_anticipated_trading_patterns: 'Patrones comerciales anticipados',
          business_anticipated_trading_patterns_select_1: 'Ninguno',
          business_anticipated_trading_patterns_select_2: 'Regulares',
          business_anticipated_trading_patterns_select_3: 'Inusuales',
          business_anticipated_monthly_transactions_incoming: 'Transacciones Mensuales Anticipadas - Entrantes',
          business_anticipated_monthly_transactions_outgoing: 'Transacciones Mensuales Anticipadas - Salientes',
          business_industry: 'Rubro de la empresa',
          administrative_services: 'Servicios administrativos',
          advertising: 'Publicidad',
          animal_farming_production: 'Producción ganadera',
          art_photography: 'Fotografía artística',
          auto_dealers: 'Concesionarios de automóviles',
          automotive: 'Industria automotriz',
          bank: 'Bancos',
          beauty_or_barber_shops: 'Belleza o Peluquería',
          biotechnology: 'Biotecnología',
          building_materials_hardware: 'Materiales de construcción',
          car_wash: 'Lavado de autos',
          clothing_apparel: 'Ropa de vestir',
          college_university_schools: 'Universidades',
          computer_service_repair: 'Servicio de reparación de computadoras',
          construction: 'Construcción',
          consumer_goods: 'Bienes de consumo',
          crop_farming: 'Producción agrícola',
          data_analytics: 'Análisis de datos',
          design: 'Diseño',
          ecommerce: 'Ecommerce',
          electronics: 'Electrónica',
          employment_services: 'Servicios de empleo',
          energy: 'Energía',
          engineering: 'Ingeniería',
          events: 'Eventos',
          financial_investments: 'Inversiones financieras',
          financial_services: 'Servicios financieros',
          financial_technology: 'Tecnología financiera',
          fishing_hunting: 'Pesca y caza',
          fitness_sports_centers: 'Centros deportivos y gimnasios',
          food: 'Gastronomía',
          forestry: 'Silvicultura',
          freelance_professional: 'Profesional independiente',
          funds_trusts_other: 'Fideicomiso de fondos',
          gaming: 'Gaming',
          gasoline_service_station: 'Estación de servicio',
          government_agency: 'Agencia gubernamental',
          hardware: 'Herramientas',
          health_services: 'Servicios de salud',
          home_furnishing: 'Muebles',
          hospitals: 'Hospitales',
          hotel_motel: 'Hotel y motel',
          industrial_machinery: 'Maquinaria industrial',
          information_technology: 'Tecnologías de la información',
          insurance: 'Seguros',
          landscape_services: 'Paisajismo',
          legal_services: 'Servicios juridico',
          lifestyle: 'Estilo de vida',
          massage_tanning_services: 'Servicios de masajes y bronceado',
          mobile: 'Servicios de telecomunicación',
          money_transfer_remittance: 'Transferencias de dinero',
          museums: 'Museos',
          music: 'Música',
          natural_resources: 'Recursos naturales',
          non_government: 'No Gubernamental',
          online_retailer: 'Ventas minoristas en línea',
          other_accommodation: 'Otros alojamientos',
          other_arts_entertainment: 'Otros entretenimientos artísticos',
          other_education_services: 'Otros servicios educativos',
          other_farming_hunting: 'Otros caz y pesca',
          other_food_services: 'Otros servicios de comida',
          other_health_fitness: 'Otros servicios de bienestar físico',
          other_manufacturing: 'Otra Manufactura',
          other_professional_services: 'Otros servicios profesionales',
          other_trade_contractor: 'Otro contratista comercial',
          other_transport_services: 'Otros transportes de servicios',
          other_travel_services: 'Otros servicios de viaje',
          parking_garages: 'Garajes de estacionamiento',
          payments: 'Pagos',
          plumbing_hvac: 'Plomería',
          private_investment: 'Inversiones privadas',
          publishing: 'Publicación y Editorial',
          real_estate: 'Bienes raíces',
          religious_organization: 'Organizaciones religiosas',
          restaurants_with_cash: 'Restaurantes Con Efectivo',
          restaurants: 'Restaurantes',
          retail_jeweler: 'Joyería minorista',
          retail_with_cash: 'Venta al por menor con efectivo',
          retail: 'Venta al por menor',
          sales_marketing: 'Ventas y Marketing',
          security: 'Seguridad',
          software_publisher: 'Software',
          sports_teams_clubs: 'Clubes de equipos deportivos',
          sports: 'Deportes',
          sustainability: 'Sustentabilidad',
          taxi: 'Taxi',
          telecommunication: 'Telecomunicación',
          tour_operator: 'Operadores de Tours',
          transportation: 'Transporte',
          travel_agency: 'Agencia de viajes',
          trucking_shipping: 'Transporte por carretera y envíos',
          unions: 'Sindicatos',
          used_clothes_dealer: 'Distribuidor de ropa usada',
          video: 'Video',
          warehouse_distribution: 'Distribución de almacén',
          wellness_services: 'Servicios de Bienestar',
          wholesale_jeweler: 'Venta de joyeria al por mayor',
          wholesale: 'Venta al por mayor',

          business_desc: 'Descripción de la Empresa',
          business_doc_submission: 'Documentación',
          business_nature: 'Naturaleza del negocio de la Empresa',
          business_inc_doc: 'Documentos de incorporación comercial (10 MB máx.)',
          business_address_confirmation: 'Confirmación de dirección de la Empresa (10 MB máx.)',
          contact_increment_ID: 'Contacto',
          contact_increment_ID_text: 'identificación emitida por el gobierno',
          contact_increment_address_text: 'Confirmación de dirección',
          bus_inc_doc_header: 'Documentos de incorporación comercial',
          bus_inc_doc_text: 'Enviar documento completos de incorporación comercial, que puede incluir el estatuto de la empresa, los artículos de incorporación, el acuerdo operativo, el registro de acciones de propiedad, etc.',
          bus_address_conf_header: 'Confirmación de dirección de la Empresa',
          bus_address_conf_text: 'Proporcionar un comprobante de domicilio (artículo de correo) fechado dentro de los últimos 90 días (p. ej., factura de servicios, resumen de tarjeta de crédito/banco, contrato de arrendamiento de alquiler, registro del vehículo, copia del título de propiedad inmobiliaria o factura de impuestos sobre la propiedad).',
          bus_gov_issues_ID_popup_header: 'IDENTIFICACIÓN',
          bus_gov_issues_ID_popup_line1: 'Siga estas instrucciones cuando envíe su identificación emitida por el gobierno. Los documentos de identificación presentados que no cumplan con los siguientes criterios pueden causar demoras en la aprobación.',
          bus_gov_issues_ID_popup_line2: 'Pasaporte: el escaneo del pasaporte debe ser de la página completa de la fotografía y mostrar también la página opuesta completa. Ambas páginas deben mostrar su margen completo. El escaneo debe ser en color y tener la menor cantidad posible de reflejos.',
          bus_gov_issues_ID_popup_line3: 'Licencia de conducir: el escaneo de la licencia de conducir debe estar completo y mostrar todos los márgenes. El escaneo debe ser en color y tener la menor cantidad posible de reflejos.',
          choose_file: 'Seleccionar archivo',
          personal_acc_heading: 'Información de contacto',
          personal_address_conf_header: 'Dirección',
          personal_doc_submission: 'Documentación',
          unable_1: 'No podemos completar tu solicitud en este momento. Por favor comunicarse con soporte a',
          unable_2: 'o por teléfono al',
          unable_3: 'para completar su solicitud',
          unable_4: 'Gracias',
          final_acc_activation: 'La activación final de la cuenta solo ocurrirá una vez que se hayan recibido todos los documentos.',
          required1: 'Necesitas llenar todos los campos requeridos. Los marcamos con color rojo para que sepas qué campos te has perdido.',
          required2: 'OEl porcentaje de propiedad de todos los beneficiarios reales debe ser un total de 100%',
          required3: 'Se debe seleccionar al menos un beneficiario real',
          form_error_required: 'Este campo es obligatorio.',
          form_error_email: 'Este debe ser un correo electrónico válido.',
          form_error_sameAs: 'Passwords must match.',
          form_error_integer: 'El valor debe ser entero.',
          form_error_numeric: 'Este campo debe ser numérico.',
          form_error_decimal: 'Este campo debe ser un número.',
          form_error_between: 'El valor esta fuera de rango.',
          form_error_alpha: 'Este campo debe ser solo de letras.',
          form_error_alphaNum: 'Este campo no admite caracteres especiales.',
          form_error_alphaNumNoSpace: 'Este campo debe ser alfanumérico.',
          form_error_alphaOnly: 'Este campo debe ser solo de letras.',
          form_error_alphaVersionNoSpace: 'Este campo no admite caracteres especiales.',
          form_error_addressValidation: 'Este campo no admite caracteres especiales.',
          form_error_dashNumbers: 'Este campo no admite caracteres especiales.',
          form_error_ipAddress: 'El valor debe ser una dirección IPv4 válida.',
          form_error_url: 'El valor debe ser una URL válida.',
          form_error_minLength: 'Se requieren más caracteres.',
          form_error_maxLength: 'Límite máximo de caracteres superado.',
          form_error_minValue: 'The value can not be less than zero.',
          form_error_limit: 'Amount Entered Exceeds Limit',
          form_error_routingNumber: 'Routing number can only start with 0, 1, 2 or 3',
          form_error_monbiAccountNumber: 'This is not a valid Account number. Please check information and try again.',
          form_error_swift: 'Enter a valid SWIFT code.',
          form_error_minLimit: 'The Amount must be greater than the Fee.',
          form_error_webSiteUrl: 'Este campo debe tener formato URL.',
        },
        months: {
          1: 'Enero',
          2: 'Febrero',
          3: 'Marzo',
          4: 'Abril',
          5: 'Mayo',
          6: 'Junio',
          7: 'Julio',
          8: 'Agosto',
          9: 'Septiembre',
          10: 'Octubre',
          11: 'Noviembre',
          12: 'Diciembre',
        },
      }
    }

export default {
  data: ()=>({
    i18n: window.globalLocale,
    fullCountries: [
      {
        id: "AF",
        text: "Afghanistan"
      },
      {
        id: "AX",
        text: "Åland Islands"
      },
      {
        id: "AL",
        text: "Albania"
      },
      {
        id: "DZ",
        text: "Algeria"
      },
      {
        id: "AS",
        text: "American Samoa"
      },
      {
        id: "AD",
        text: "Andorra"
      },
      {
        id: "AO",
        text: "Angola"
      },
      {
        id: "AI",
        text: "Anguilla"
      },
      {
        id: "AQ",
        text: "Antarctica"
      },
      {
        id: "AG",
        text: "Antigua and Barbuda"
      },
      {
        id: "AR",
        text: "Argentina"
      },
      {
        id: "AM",
        text: "Armenia"
      },
      {
        id: "AW",
        text: "Aruba"
      },
      {
        id: "AU",
        text: "Australia"
      },
      {
        id: "AT",
        text: "Austria"
      },
      {
        id: "AZ",
        text: "Azerbaijan"
      },
      {
        id: "BS",
        text: "Bahamas"
      },
      {
        id: "BH",
        text: "Bahrain"
      },
      {
        id: "BD",
        text: "Bangladesh"
      },
      {
        id: "BB",
        text: "Barbados"
      },
      {
        id: "BY",
        text: "Belarus"
      },
      {
        id: "BE",
        text: "Belgium"
      },
      {
        id: "BZ",
        text: "Belize"
      },
      {
        id: "BJ",
        text: "Benin"
      },
      {
        id: "BM",
        text: "Bermuda"
      },
      {
        id: "BT",
        text: "Bhutan"
      },
      {
        id: "BO",
        text: "Bolivia"
      },
      {
        id: "BQ",
        text: "Bonaire, Sint Eustatius and Saba"
      },
      {
        id: "BA",
        text: "Bosnia and Herzegovina"
      },
      {
        id: "BW",
        text: "Botswana"
      },
      {
        id: "BV",
        text: "Bouvet Island"
      },
      {
        id: "BR",
        text: "Brazil"
      },
      {
        id: "IO",
        text: "British Indian Ocean Territory"
      },
      {
        id: "BN",
        text: "Brunei Darussalam"
      },
      {
        id: "BG",
        text: "Bulgaria"
      },
      {
        id: "BF",
        text: "Burkina Faso"
      },
      {
        id: "BI",
        text: "Burundi"
      },
      {
        id: "KH",
        text: "Cambodia"
      },
      {
        id: "CM",
        text: "Cameroon"
      },
      {
        id: "CA",
        text: "Canada"
      },
      {
        id: "CV",
        text: "Cape Verde"
      },
      {
        id: "KY",
        text: "Cayman Islands"
      },
      {
        id: "CF",
        text: "Central African Republic"
      },
      {
        id: "TD",
        text: "Chad"
      },
      {
        id: "CL",
        text: "Chile"
      },
      {
        id: "CN",
        text: "China"
      },
      {
        id: "CX",
        text: "Christmas Island"
      },
      {
        id: "CC",
        text: "Cocos (Keeling) Islands"
      },
      {
        id: "CO",
        text: "Colombia"
      },
      {
        id: "KM",
        text: "Comoros"
      },
      {
        id: "CG",
        text: "Congo"
      },
      {
        id: "CD",
        text: "Congo, Democratic Republic of the Congo"
      },
      {
        id: "CK",
        text: "Cook Islands"
      },
      {
        id: "CR",
        text: "Costa Rica"
      },
      {
        id: "CI",
        text: "Cote D'Ivoire"
      },
      {
        id: "HR",
        text: "Croatia"
      },
      {
        id: "CU",
        text: "Cuba"
      },
      {
        id: "CW",
        text: "Curaçao"
      },
      {
        id: "CY",
        text: "Cyprus"
      },
      {
        id: "CZ",
        text: "Czech Republic"
      },
      {
        id: "DK",
        text: "Denmark"
      },
      {
        id: "DJ",
        text: "Djibouti"
      },
      {
        id: "DM",
        text: "Dominica"
      },
      {
        id: "DO",
        text: "Dominican Republic"
      },
      {
        id: "EC",
        text: "Ecuador"
      },
      {
        id: "EG",
        text: "Egypt"
      },
      {
        id: "SV",
        text: "El Salvador"
      },
      {
        id: "GQ",
        text: "Equatorial Guinea"
      },
      {
        id: "ER",
        text: "Eritrea"
      },
      {
        id: "EE",
        text: "Estonia"
      },
      {
        id: "ET",
        text: "Ethiopia"
      },
      {
        id: "FK",
        text: "Falkland Islands (Malvinas)"
      },
      {
        id: "FO",
        text: "Faroe Islands"
      },
      {
        id: "FJ",
        text: "Fiji"
      },
      {
        id: "FI",
        text: "Finland"
      },
      {
        id: "FR",
        text: "France"
      },
      {
        id: "GF",
        text: "French Guiana"
      },
      {
        id: "PF",
        text: "French Polynesia"
      },
      {
        id: "TF",
        text: "French Southern Territories"
      },
      {
        id: "GA",
        text: "Gabon"
      },
      {
        id: "GM",
        text: "Gambia"
      },
      {
        id: "GE",
        text: "Georgia"
      },
      {
        id: "DE",
        text: "Germany"
      },
      {
        id: "GH",
        text: "Ghana"
      },
      {
        id: "GI",
        text: "Gibraltar"
      },
      {
        id: "GR",
        text: "Greece"
      },
      {
        id: "GL",
        text: "Greenland"
      },
      {
        id: "GD",
        text: "Grenada"
      },
      {
        id: "GP",
        text: "Guadeloupe"
      },
      {
        id: "GU",
        text: "Guam"
      },
      {
        id: "GT",
        text: "Guatemala"
      },
      {
        id: "GG",
        text: "Guernsey"
      },
      {
        id: "GN",
        text: "Guinea"
      },
      {
        id: "GW",
        text: "Guinea-Bissau"
      },
      {
        id: "GY",
        text: "Guyana"
      },
      {
        id: "HT",
        text: "Haiti"
      },
      {
        id: "HM",
        text: "Heard Island and McDonald Islands"
      },
      {
        id: "VA",
        text: "Holy See (Vatican City State)"
      },
      {
        id: "HN",
        text: "Honduras"
      },
      {
        id: "HK",
        text: "Hong Kong"
      },
      {
        id: "HU",
        text: "Hungary"
      },
      {
        id: "IS",
        text: "Iceland"
      },
      {
        id: "IN",
        text: "India"
      },
      {
        id: "ID",
        text: "Indonesia"
      },
      {
        id: "IR",
        text: "Iran, Islamic Republic of"
      },
      {
        id: "IQ",
        text: "Iraq"
      },
      {
        id: "IE",
        text: "Ireland"
      },
      {
        id: "IM",
        text: "Isle of Man"
      },
      {
        id: "IL",
        text: "Israel"
      },
      {
        id: "IT",
        text: "Italy"
      },
      {
        id: "JM",
        text: "Jamaica"
      },
      {
        id: "JP",
        text: "Japan"
      },
      {
        id: "JE",
        text: "Jersey"
      },
      {
        id: "JO",
        text: "Jordan"
      },
      {
        id: "KZ",
        text: "Kazakhstan"
      },
      {
        id: "KE",
        text: "Kenya"
      },
      {
        id: "KI",
        text: "Kiribati"
      },
      {
        id: "KP",
        text: "(North Korea) Korea, Democratic People's Republic of"
      },
      {
        id: "KR",
        text: "(South Korea) Korea, Republic of (South Korea)"
      },
      {
        id: "XK",
        text: "Kosovo"
      },
      {
        id: "KW",
        text: "Kuwait"
      },
      {
        id: "KG",
        text: "Kyrgyzstan"
      },
      {
        id: "LA",
        text: "Lao People's Democratic Republic"
      },
      {
        id: "LV",
        text: "Latvia"
      },
      {
        id: "LB",
        text: "Lebanon"
      },
      {
        id: "LS",
        text: "Lesotho"
      },
      {
        id: "LR",
        text: "Liberia"
      },
      {
        id: "LY",
        text: "Libyan Arab Jamahiriya"
      },
      {
        id: "LI",
        text: "Liechtenstein"
      },
      {
        id: "LT",
        text: "Lithuania"
      },
      {
        id: "LU",
        text: "Luxembourg"
      },
      {
        id: "MO",
        text: "Macao"
      },
      {
        id: "MK",
        text: "Macedonia, the Former Yugoslav Republic of"
      },
      {
        id: "MG",
        text: "Madagascar"
      },
      {
        id: "MW",
        text: "Malawi"
      },
      {
        id: "MY",
        text: "Malaysia"
      },
      {
        id: "MV",
        text: "Maldives"
      },
      {
        id: "ML",
        text: "Mali"
      },
      {
        id: "MT",
        text: "Malta"
      },
      {
        id: "MH",
        text: "Marshall Islands"
      },
      {
        id: "MQ",
        text: "Martinique"
      },
      {
        id: "MR",
        text: "Mauritania"
      },
      {
        id: "MU",
        text: "Mauritius"
      },
      {
        id: "YT",
        text: "Mayotte"
      },
      {
        id: "MX",
        text: "Mexico"
      },
      {
        id: "FM",
        text: "Micronesia, Federated States of"
      },
      {
        id: "MD",
        text: "Moldova, Republic of"
      },
      {
        id: "MC",
        text: "Monaco"
      },
      {
        id: "MN",
        text: "Mongolia"
      },
      {
        id: "ME",
        text: "Montenegro"
      },
      {
        id: "MS",
        text: "Montserrat"
      },
      {
        id: "MA",
        text: "Morocco"
      },
      {
        id: "MZ",
        text: "Mozambique"
      },
      {
        id: "MM",
        text: "Myanmar"
      },
      {
        id: "NA",
        text: "Namibia"
      },
      {
        id: "NR",
        text: "Nauru"
      },
      {
        id: "NP",
        text: "Nepal"
      },
      {
        id: "NL",
        text: "Netherlands"
      },
      {
        id: "AN",
        text: "Netherlands Antilles"
      },
      {
        id: "NC",
        text: "New Caledonia"
      },
      {
        id: "NZ",
        text: "New Zealand"
      },
      {
        id: "NI",
        text: "Nicaragua"
      },
      {
        id: "NE",
        text: "Niger"
      },
      {
        id: "NG",
        text: "Nigeria"
      },
      {
        id: "NU",
        text: "Niue"
      },
      {
        id: "NF",
        text: "Norfolk Island"
      },
      {
        id: "MP",
        text: "Northern Mariana Islands"
      },
      {
        id: "NO",
        text: "Norway"
      },
      {
        id: "OM",
        text: "Oman"
      },
      {
        id: "PK",
        text: "Pakistan"
      },
      {
        id: "PW",
        text: "Palau"
      },
      {
        id: "PS",
        text: "Palestinian Territory, Occupied"
      },
      {
        id: "PA",
        text: "Panama"
      },
      {
        id: "PG",
        text: "Papua New Guinea"
      },
      {
        id: "PY",
        text: "Paraguay"
      },
      {
        id: "PE",
        text: "Peru"
      },
      {
        id: "PH",
        text: "Philippines"
      },
      {
        id: "PN",
        text: "Pitcairn"
      },
      {
        id: "PL",
        text: "Poland"
      },
      {
        id: "PT",
        text: "Portugal"
      },
      {
        id: "PR",
        text: "Puerto Rico"
      },
      {
        id: "QA",
        text: "Qatar"
      },
      {
        id: "RE",
        text: "Réunion"
      },
      {
        id: "RO",
        text: "Romania"
      },
      {
        id: "RU",
        text: "Russian Federation"
      },
      {
        id: "RW",
        text: "Rwanda"
      },
      {
        id: "BL",
        text: "Saint Barthélemy"
      },
      {
        id: "SH",
        text: "Saint Helena"
      },
      {
        id: "KN",
        text: "Saint Kitts and Nevis"
      },
      {
        id: "LC",
        text: "Saint Lucia"
      },
      {
        id: "MF",
        text: "Saint Martin"
      },
      {
        id: "PM",
        text: "Saint Pierre and Miquelon"
      },
      {
        id: "VC",
        text: "Saint Vincent and the Grenadines"
      },
      {
        id: "WS",
        text: "Samoa"
      },
      {
        id: "SM",
        text: "San Marino"
      },
      {
        id: "ST",
        text: "Sao Tome and Principe"
      },
      {
        id: "SA",
        text: "Saudi Arabia"
      },
      {
        id: "SN",
        text: "Senegal"
      },
      {
        id: "RS",
        text: "Serbia"
      },
      {
        id: "CS",
        text: "Serbia and Montenegro"
      },
      {
        id: "SC",
        text: "Seychelles"
      },
      {
        id: "SL",
        text: "Sierra Leone"
      },
      {
        id: "SG",
        text: "Singapore"
      },
      {
        id: "SX",
        text: "Sint Maarten (Dutch part)"
      },
      {
        id: "SK",
        text: "Slovakia"
      },
      {
        id: "SI",
        text: "Slovenia"
      },
      {
        id: "SB",
        text: "Solomon Islands"
      },
      {
        id: "SO",
        text: "Somalia"
      },
      {
        id: "ZA",
        text: "South Africa"
      },
      {
        id: "GS",
        text: "South Georgia and the South Sandwich Islands"
      },
      {
        id: "SS",
        text: "South Sudan"
      },
      {
        id: "ES",
        text: "Spain"
      },
      {
        id: "LK",
        text: "Sri Lanka"
      },
      {
        id: "SD",
        text: "Sudan"
      },
      {
        id: "SR",
        text: "Suriname"
      },
      {
        id: "SJ",
        text: "Svalbard and Jan Mayen"
      },
      {
        id: "SZ",
        text: "Swaziland"
      },
      {
        id: "SE",
        text: "Sweden"
      },
      {
        id: "CH",
        text: "Switzerland"
      },
      {
        id: "SY",
        text: "Syrian Arab Republic"
      },
      {
        id: "TW",
        text: "Taiwan, Province of China"
      },
      {
        id: "TJ",
        text: "Tajikistan"
      },
      {
        id: "TZ",
        text: "Tanzania, United Republic of"
      },
      {
        id: "TH",
        text: "Thailand"
      },
      {
        id: "TL",
        text: "Timor-Leste"
      },
      {
        id: "TG",
        text: "Togo"
      },
      {
        id: "TK",
        text: "Tokelau"
      },
      {
        id: "TO",
        text: "Tonga"
      },
      {
        id: "TT",
        text: "Trinidad and Tobago"
      },
      {
        id: "TN",
        text: "Tunisia"
      },
      {
        id: "TR",
        text: "Turkey"
      },
      {
        id: "TM",
        text: "Turkmenistan"
      },
      {
        id: "TC",
        text: "Turks and Caicos Islands"
      },
      {
        id: "TV",
        text: "Tuvalu"
      },
      {
        id: "UG",
        text: "Uganda"
      },
      {
        id: "UA",
        text: "Ukraine"
      },
      {
        id: "AE",
        text: "United Arab Emirates"
      },
      {
        id: "GB",
        text: "United Kingdom"
      },
      {
        id: "US",
        text: "United States"
      },
      {
        id: "UM",
        text: "United States Minor Outlying Islands"
      },
      {
        id: "UY",
        text: "Uruguay"
      },
      {
        id: "UZ",
        text: "Uzbekistan"
      },
      {
        id: "VU",
        text: "Vanuatu"
      },
      {
        id: "VE",
        text: "Venezuela"
      },
      {
        id: "VN",
        text: "Viet Nam"
      },
      {
        id: "VG",
        text: "Virgin Islands, British"
      },
      {
        id: "VI",
        text: "Virgin Islands, U.S."
      },
      {
        id: "WF",
        text: "Wallis and Futuna"
      },
      {
        id: "EH",
        text: "Western Sahara"
      },
      {
        id: "YE",
        text: "Yemen"
      },
      {
        id: "ZM",
        text: "Zambia"
      },
      {
        id: "ZW",
        text: "Zimbabwe"
      }
    ],
    yearsOptions: (()=>{
      var result = {}
      var currentYear = (new Date()).getFullYear()
      for(let i = currentYear - 100; i <= window.currentYear; i++)
        result[i] = i
      return result
    })(),
  }),
  methods: {
    resolveDotPath(obj, path) {
      if (!obj)
        return obj
      var parts = path.split(".")
      for (var part of parts) {
        obj=obj[part]
        if (!obj)
          return null
      }
      return obj
    },
    $t (value) {
      return this.resolveDotPath(this.i18n[this.i18n.locale], value) || value
    },
  },
}
