import { render, staticRenderFns } from "./RegisterForm2.vue?vue&type=template&id=20b39432&scoped=true&"
import script from "./RegisterForm2.vue?vue&type=script&lang=js&"
export * from "./RegisterForm2.vue?vue&type=script&lang=js&"
import style0 from "./RegisterForm2.vue?vue&type=style&index=0&id=20b39432&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b39432",
  null
  
)

export default component.exports