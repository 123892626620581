<template>
  <div>
    <label v-if="label">{{ label }} <em v-if="required">*</em></label>
    <slot />
    <div v-if="hasError" class="error-message">
      <span v-for="error in errors" :key="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },

    required: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Array,
      default: () => []
    },
  },

  computed: {
    hasError () {
      return this.errors.length > 0
    }
  }
}
</script>
