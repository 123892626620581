<template>
  <section class="popup" :class="{open: open}">
    <div class="pop-inner" :style="{ maxWidth: `${width}px` }">
      <div class="pop-box">
        <a class="close" @click.prevent="$emit('update:open', false); $emit('close')"></a>
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true
    },

    width: {
      type: Number,
      default: 700
    }
  }
}
</script>
